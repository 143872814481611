import { GET_AGENT_DETAILS, CHANGE_PASSWORD, EDIT_AGENT, API_ERROR, UPDATE_AGENT_TOUR_STATUS } from 'store/settings/constants'

const initialState = {
  error: '',
  loading: false,
  agent_details: {},
  edit_loading: false,
}

const settings = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD.REQUEST:
      state = { ...state, loading: true, error: '' }
      break

    case CHANGE_PASSWORD.SUCCESS:
      state = { ...state, error: '', loading: false }
      break

    case GET_AGENT_DETAILS.REQUEST:
      state = { ...state, loading: true, error: '' }
      break

    case GET_AGENT_DETAILS.SUCCESS:
      state = {
        ...state,
        loading: false,
        agent_details: action.payload.data?.get_agent_profile?.[0]?.agent_details?.[0],
      }
      break

    case EDIT_AGENT.REQUEST:
      return {
        ...state,
        edit_loading: true,
      }

    case EDIT_AGENT.SUCCESS:
      return {
        ...state,
        edit_loading: false,
      }

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, edit_loading: false }
      break
  
    default:
      state = { ...state }
      break
  }

  return state
}

export default settings
