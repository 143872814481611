import { get, post, put } from 'helpers/axios'

import { changePasswordUrl, editAgentUrl, getAgentDetailsUrl, updateTourStatusUrl } from 'api/endpoints'

const changePassword = (data) => {
  return post(changePasswordUrl, data)
}

const editAgent = (data: any) => {
  return put(editAgentUrl, data)
}

const getAgentDetails = () => {
  return get(getAgentDetailsUrl)
}

const updateAgentTourStatus = (data: {is_agent_tour_completed:true}) => {
  return put(updateTourStatusUrl, data)
}

const settingServices = {
  changePassword,
  editAgent,
  getAgentDetails,
  updateAgentTourStatus
}

export default settingServices
