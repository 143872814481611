import React from 'react'
import { Link } from 'react-router-dom'

import moment from 'moment'

//icons
import divider from 'assets/icons/grey-divider.svg'
import pencil from 'assets/icons/pencil.svg'

const Detail = ({ label, value }) => {
  return (
    <div>
      <p className='grey-text font-light text-xs'>{label}</p>
      <p className='black-text-3 text-xs font-medium capitalize mt-1.5'>{value}</p>
    </div>
  )
}

const ProfileDetails = ({ user, agent_details, agent_status }) => {
  const status_names = {
    on_hold: 'On Hold',
    new: 'Pending Completion',
    verified: 'Verified',
  }

  console.log(user, agent_details)

  return (
    <div className='border-grey rounded mb-6'>
      <div className='flex items-center justify-between bottom-divider-2 p-6 pb-4'>
        <div className='flex items-center gap-x-2'>
          <p className='black-text-3 font-medium capitalize'>{user.company_name}</p>
          <p>
            <img src={divider} alt='' />
          </p>
          <p
            className={`rounded-full text-xs ${
              agent_details?.verify_status?.toLowerCase() === 'on_hold'
                ? 'text-[#AD0013] bg-[#FFEDEE]'
                : agent_details?.verify_status?.toLowerCase() === 'new'
                ? 'text-[#AD0013] bg-[#FFEDEE]'
                : agent_details?.verify_status?.toLowerCase() === 'verified'
                ? 'text-[#005D00] bg-[#99FF7D2E]'
                : ''
            }  font-medium  py-1 px-4 capitalize`}
          >
            {(status_names[agent_details?.verify_status] || status_names[agent_status?.verify_status]) ?? 'N/A'}
          </p>
        </div>
        <div>
          <Link to='/settings/profile-edit' className='flex items-center gap-1 ml-auto text-base'>
            <img src={pencil} alt='' />
            <span className='green-text text-sm hidden md:block'>Edit details</span>
          </Link>
        </div>
      </div>
      <div className='grid grid-cols-2 max-md:gap-x-6 lg:grid-cols-3 gap-y-6 p-6'>
        <Detail label='First Name' value={user.firstname} />
        <Detail label='Last Name' value={user.lastname} />
        <div>
          <p className='grey-text font-light text-xs'>Email</p>
          <p className='black-text-3 md:hidden text-xs font-medium mt-1.5'>
            {user?.email.length > 25 ? `${user.email.slice(0, 25)}...` : user.email}
          </p>
          <p className='black-text-3 max-md:hidden text-xs font-medium mt-1.5'>{user.email}</p>
        </div>
        <Detail label='Phone Number' value={user.phone} />
        <Detail label='Date Registered' value={moment(user?.createdAt).format('DD-MM-YYYY')} />
      </div>
    </div>
  )
}

export default ProfileDetails
