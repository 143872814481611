import { call, put, take, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import settingServices from 'api/services/settings'

import { errorHandler } from 'helpers/errorHandler'

import { GET_AGENT_DETAILS, CHANGE_PASSWORD, EDIT_AGENT, UPDATE_AGENT_TOUR_STATUS } from 'store/settings/constants'

import { ResponseGenerator } from 'store/type'

import { updateUser, updateAgent, settingsApiError, editAgentSuccess, changePasswordSuccess, getAgentDetailsSuccess } from 'store/actions'

function* changePassword({ payload }: any) {
  try {
    const { data, callback, isInApp } = payload

    const response: ResponseGenerator = yield call(settingServices.changePassword, data)

    if (response.data.status === 'success') {
      yield put(changePasswordSuccess(response.data))
      toast.success(`Password changed successfully ${isInApp ? ", you'll be required to sign in again" : ''}`)
      callback()
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(settingsApiError(message))
  }
}

function* editAgent({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(settingServices.editAgent, data)

    if (response.data.status === 'success') {
      yield put(editAgentSuccess(response.data))
      toast.success('Profile Successfully Updated')
      console.log('ifes>>>', response.data.data)
      window.location.replace(`${window.location.origin}/settings/profile`)
      callback(response.data.data.updateAgentDetails)
    }
  } catch (error) {
    const message = error['response']['data']['message'] || error.message || 'network error'
    toast.error(message)
    yield put(settingsApiError(error))
  }
}

function* getAgentDetails({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(settingServices.getAgentDetails)

    if (response.data) {
      const { agent_details: removedProperty, ...newObject } = response.data.data?.get_agent_profile?.[0]
      yield put(updateUser(newObject))
      yield put(getAgentDetailsSuccess(response.data))
      yield put(updateAgent(response?.data?.data?.get_agent_profile?.[0].agent_details?.[0]))
      localStorage.setItem('agent_data', JSON.stringify(response?.data?.data?.get_agent_profile?.[0].agent_details?.[0]))
    }
  } catch (error) {
    const message = errorHandler(error, false)
    yield put(settingsApiError(message))
  }
}

function* updateAgentTourStatus ({payload}:any) {
  try {
    const response: ResponseGenerator = yield call(settingServices.updateAgentTourStatus, payload)
    if(response.data){
      console.log(response.data)
    }
  } catch (error) {
    const message = error['response']['data']['message'] || error.message || 'network error'
    toast.error(message)
    yield put(settingsApiError(error))
  }
}

function* settingSaga() {
  yield takeEvery(GET_AGENT_DETAILS.REQUEST, getAgentDetails)
  yield takeEvery(CHANGE_PASSWORD.REQUEST, changePassword)
  yield takeEvery(EDIT_AGENT.REQUEST, editAgent)
  yield takeEvery(UPDATE_AGENT_TOUR_STATUS.SUCCESS, updateAgentTourStatus)
}

export default settingSaga
