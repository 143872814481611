import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'

//icons
import arrowL from 'assets/icons/arrow-left2.svg'
import infoError from 'assets/icons/info-error.svg'
import info from 'assets/icons/cbt-info.svg'

//libraries
import { useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'

//actions
import { editAgent, getAgentDetails, updateUser, updateAgent } from 'store/actions'

// input options
import { shipmentVolumeOptions, businessTypeOptions, industryTypeOptions, interestedServicesOptions, portOfShipmentOptions } from './options'

//components
import Button from 'components/dock/Button'
import Layout from 'components/layout/Layout'
import CustomInput from 'components/textInputs/CustomInput'
import CBTNotification from 'components/rate/exportOcean/CBTNotification'
import PageLoading from 'components/partials/pageLoading'
import AgentEnquiryModal from 'components/settings/AgentEnquiryModal'
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'
import InvalidCACModal from 'components/settings/InvalidCACModal'
import SecondaryButtons from 'components/buttons/SecondaryButtons'
import CustomGoogleInput from 'components/textInputs/CustomGoogleInput'
import CustomCheckBox from 'components/checkBox/CustomCheckbox'

const BusinessEdit = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { loading, agent_details, getAgentDetails, edit_loading, editAgent, updateAgent } = props

  let user = useSelector((state: any) => state.auth.user_data)
  let localStorageUser = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')!) : null
  user = user ? user : localStorageUser

  let agent_status = useSelector((state: any) => state.auth.agent_data)
  agent_status = agent_status || JSON.parse(localStorage.getItem('agent_data') || '{}')

  console.log('profile_agent>>>', agent_details)

  useEffect(() => {
    setCompanyAddressError(false)
    getAgentDetails()
  }, [])

  const navigate = useNavigate()

  const handleUpdateUser = (agent) => {
    updateAgent(agent)
    localStorage.setItem('agent_data', JSON.stringify(agent))
    console.log('updatedUser...', agent)
  }

  const closeCacModal = () => {
    setOpenInvalidCACModal(false)
  }

  const closeEnquiryModal = () => {
    setOpenEnquiryModal(false)
  }

  const openAgentEnquiryModal = () => {
    setOpenInvalidCACModal(false)
    setOpenEnquiryModal(true)
  }

  const onSubmit = (data: any) => {
    if (data.company_address.label === '') {
      setCompanyAddressError(true)
      return
    } else {
      const newData = {
        cac_number: data.cac_number,
        verify_status: 'verified',
        company_address: data.company_address.label,
        business_type: data.business_type.value,
        industry_type: selectedIndustryType.map((item) => item.value),
        monthly_shipment_volume: data.monthly_shipment_volume.value,
        interested_services: selectedInterestedServices.map((item) => item.value),
        major_port_of_shipment: data.major_port_of_shipment.value,
      }
      console.log('newDare>>>', newData)
      editAgent(newData, handleUpdateUser)
      setCompanyAddressError(false)
    }
  }

  const [selectedIndustryType, setSelectedIndustryType] = useState<any>([])
  const [selectedInterestedServices, setSelectedInterestedServices] = useState<any>([])

  useEffect(() => {
    // set map over industry type and interested services and set the them as default
    if (agent_details?.industry_type?.length > 0) {
      setSelectedIndustryType(
        agent_details?.industry_type.map((industry) => {
          const label = industry
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          return { label, value: industry }
        })
      )
    }
    if (agent_details?.interested_services?.length > 0)
      setSelectedInterestedServices(
        agent_details?.interested_services?.map((service) => {
          const label = service
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
          return { label, value: service }
        })
      )
  }, [agent_details])

  let cacTrialsLeft = 3

  const isAgentOnHold = agent_status?.verify_status?.toLowerCase() === 'on_hold'
  const isAgentVerified = agent_status?.verify_status?.toLowerCase() === 'verified'
  const disableFields = isAgentVerified || (cacTrialsLeft === 0 && isAgentOnHold)

  const [openEnquiryModal, setOpenEnquiryModal] = useState(false)
  const [openInvalidCACModal, setOpenInvalidCACModal] = useState(cacTrialsLeft === 0 && !isAgentVerified ? true : false)
  const [cacError, setCacError] = useState(false)
  const [companyAddressError, setCompanyAddressError] = useState(false)

  const defaultBusinessTypeOption = businessTypeOptions.find((option) => option.value === agent_details.business_type)
  const defaultMonthlyShipmentOption = shipmentVolumeOptions.find((option) => option.value === agent_details.monthly_shipment_volume)
  const defaultPortOfShipmentOption = portOfShipmentOptions.find((option) => option.value === agent_details.major_port_of_shipment)

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {loading ? (
          <PageLoading title='agent details' />
        ) : (
          <>
            <div className='flex items-center gap-x-1 mb-2 cursor-pointer w-fit' onClick={() => navigate('/settings/profile')}>
              <div>
                <img src={arrowL} alt=''></img>
              </div>
              <p className='text-sm grey-text'>Profile</p>
            </div>
            <p className='text-2xl mb-1'>Add Business Details</p>
            <p className='grey-text text-sm'>Kindly update the necessary details</p>
            <div className='flex flex-col-reverse md:grid mt-6 md:grid-cols-3 items-start gap-6'>
              <div className='w-full md:col-span-2'>
                {isAgentOnHold ||
                  (isAgentVerified && (
                    <div className='max-w-[834px] my-6'>
                      <CBTNotification text='Please  contact  support  to make changes to your profile' isBold={false} editInfo={true} />
                    </div>
                  ))}
                <form className='w-full lg:max-w-[834px]' onSubmit={handleSubmit(onSubmit)}>
                  <div className='grid gap-3 grid-cols-1 md:grid-cols-2'>
                    <CustomInput
                      control={control}
                      name={'company_name'}
                      id={'company_name'}
                      label={'Company Name'}
                      placeholder={'E.g Max Movers Limited '}
                      isRequired={true}
                      type={'text'}
                      errors={errors}
                      style={{ textTransform: 'capitalize' }}
                      isDisabled={disableFields}
                      defaultValue={user.company_name}
                      min={''}
                      max={''}
                      icon={''}
                    />
                    <div>
                      <CustomGoogleInput
                        icon=''
                        control={control}
                        name={'company_address'}
                        id={'company_address'}
                        label={'Company Address'}
                        placeholder={'Enter your registered company address'}
                        isRequired={true}
                        errors={errors}
                        isDisabled={disableFields}
                        defaultValue={user.company_address ? user.company_address : ''}
                        allowWorldWide={false}
                        isBorderTransparent={true}
                      />
                      {companyAddressError && <div className='mt-2 error-text'>Company Address is required</div>}
                    </div>
                    <CustomInput
                      control={control}
                      name={'cac_number'}
                      id={'cac_number'}
                      label={'CAC Number'}
                      placeholder={'Input a valid CAC number'}
                      isRequired={true}
                      type='text'
                      errors={errors}
                      isDisabled={disableFields}
                      defaultValue={agent_details.cac_number ? agent_details.cac_number : ''}
                      min={''}
                      max={''}
                      icon={''}
                    />
                    <CustomDefaultSelect
                      name={`business_type`}
                      id={`business_type`}
                      label={'Business Type'}
                      placeholder={'Choose a Business type'}
                      isRequired={true}
                      errors={errors}
                      isDisabled={disableFields}
                      options={businessTypeOptions}
                      icon=''
                      control={control}
                      defaultValue={defaultBusinessTypeOption}
                    />
                    <div>
                      <p className='text-sm grey-text font-light'> Industry Type</p>
                      <MultiSelect
                        hasSelectAll={false}
                        options={industryTypeOptions}
                        disabled={disableFields}
                        value={selectedIndustryType}
                        onChange={setSelectedIndustryType}
                        labelledBy='Select'
                        className='mt-2 text-sm'
                      />
                    </div>
                    <CustomDefaultSelect
                      name={`monthly_shipment_volume`}
                      id={`monthly_shipment_volume`}
                      label={'Monthly Shipment Volume'}
                      placeholder={'Choose Monthly Shipment Volume'}
                      info='You can select only one option'
                      isRequired={true}
                      errors={errors}
                      isDisabled={disableFields}
                      options={shipmentVolumeOptions}
                      icon=''
                      control={control}
                      defaultValue={defaultMonthlyShipmentOption}
                    />
                    <div>
                      <p className='text-sm grey-text font-light'> Interested Services</p>
                      <MultiSelect
                        hasSelectAll={false}
                        options={interestedServicesOptions}
                        value={selectedInterestedServices}
                        onChange={setSelectedInterestedServices}
                        labelledBy='Select'
                        className='mt-2 text-sm'
                        disabled={disableFields}
                      />
                    </div>
                    <CustomDefaultSelect
                      name={`major_port_of_shipment`}
                      id={`major_port_of_shipment`}
                      label={'Major Port of Shipment'}
                      placeholder={'Select Major Port of Shipment'}
                      isRequired={true}
                      errors={errors}
                      isDisabled={disableFields}
                      options={portOfShipmentOptions}
                      icon=''
                      control={control}
                      defaultValue={defaultPortOfShipmentOption}
                    />
                  </div>

                  <div className='mt-12 flex items-center gap-x-4 justify-end col-span-2'>
                    {!isAgentVerified ? (
                      <Button title='Save' isColored={false} style={{ width: '100%', fontWeight: '500' }} loading={edit_loading} />
                    ) : (
                      <SecondaryButtons
                        title='Contact Support'
                        style={{ fontWeight: '400', backgroundColor: '#F9FAFB' }}
                        loading={edit_loading}
                        onClick={() => setOpenEnquiryModal(true)}
                        type='button'
                      />
                    )}
                  </div>
                </form>
              </div>
              {cacError && (
                <div className='bg-[#FFBAB333] mt-6 text-xs h-[92px] w-full md:max-w-[262px] py-4 px-6 flex justify-center rounded-[4px] items-center'>
                  <div className='flex items-center justify-center gap-2'>
                    <img className='' src={infoError} alt='info-danger' />
                    <p>
                      Your CAC number does not match your Company name. You have <span className='font-bold text-[#8A0000]'>{cacTrialsLeft}</span>{' '}
                      tries left.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <AgentEnquiryModal isOpen={openEnquiryModal} closeModal={closeEnquiryModal} />
        <InvalidCACModal openEnquiryModal={openAgentEnquiryModal} modalIsOpen={openInvalidCACModal} closeModal={closeCacModal} />
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { loading, agent_details, edit_loading } = state.settings

  return { loading, agent_details, edit_loading }
}

export default connect(mapStateToProps, { editAgent, getAgentDetails, updateUser, updateAgent })(BusinessEdit)
