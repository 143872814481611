import React, { useEffect, useState } from 'react'
import Modal from "react-modal"
import closeSvg from 'assets/icons/close.svg'
import DashboardModal from "../../assets/icons/dashboard/Dashboard-Verified.png"
import CustomButton from 'components/buttons/CustomButton'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { useTour } from '@reactour/tour'
import { useSelector, useDispatch } from 'react-redux'
import { updateTourStatus } from 'store/actions'

const WelcomeModal = () => {

    const [isShowModal, setIsShowModal] = useState<boolean>(true)

    const {setIsOpen, setCurrentStep, isOpen, currentStep} = useTour()

    const dispatch = useDispatch()

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          // padding: " 1.5rem",
          // maxHeight: "calc(100vh - 100px)",
          // overflow: "auto",
          // WebkitOverflowScrolling: "touch",
          transform: 'translate(-50%, -50%)',
          width: '500px',
          borderRadius: '10px',
          border: '0.01px solid #888',
          padding: 0
        },
        overlay: {
          zIndex: '90',
          backgroundColor: 'rgba(6, 24, 2, 0.55)',
        },
        
      }

      function handleSkipTour () {
          dispatch(updateTourStatus({is_agent_tour_completed: true}))
          setIsShowModal(false)
      }

      const handleStartTour = () => {
         setIsShowModal(false)
         setIsOpen(true)
         setCurrentStep(0)
       }

       useEffect(() => {
         if(currentStep == 4){
          setIsShowModal(false)
         }
       }, [isOpen])

  return (
    <>
     <Modal isOpen={isShowModal} style={customStyles} className={"welcomemodal"}>
     <div className='flex relative flex-col max-h-[548px] overflow-auto'>
        <div className='absolute right-0 pt-[18px] pr-[18px] cursor-pointer'>
           <img onClick={handleSkipTour} src={closeSvg} alt="dashbooard-modal"/>
        </div>
        <div className='h-[250px] px-[50px] py-[70px]'>
       
           <img src={DashboardModal} alt='dashboard-modal' className='min-w-full h-[305.28px]'/>
        </div>
        <div className='z-[50px] bg-[#111827] min-h-[298px]'>
           <div className='flex flex-col gap-[36px] pt-[40px] pb-[36px] text-[#fff] text-center'>
              <span className='text-[24px] font-bold'>Welcome to Oneport 365</span>
                <span className='text-[12px]'>This is your agent dashboard where we you can create and monitor your bookings, shipments and containers. Let’s give you a quick tour!</span>
           </div>
           <div className='flex flex-col items-center gap-[24px] justify-center'>
           <PrimaryButtons
                title='Get Started'
                style={{width: "386px"}}
                disabled={false}
                loading={false}
                icon={''}
                class_names='min-w-[320px] max-w-[320px] md:min-w-[386px]'
                onClick={handleStartTour}
              />
              <button onClick={handleSkipTour} className='text-[#37B248]'>Skip</button>
           </div>
        </div>
     </div>
      
     </Modal>
       
    </>
  )
}

export default WelcomeModal