import React from 'react'
import { Link } from 'react-router-dom'

//icons
import divider from 'assets/icons/grey-divider.svg'
import pencil from 'assets/icons/pencil.svg'
import CBTNotification from 'components/rate/exportOcean/CBTNotification'

import { industryTypeMap, interestedServicesMap, shipmentVolumeMap } from './options'

const portOfShipment = {
  LOS: 'Lagos',
  PHC: 'Port Harcourt',
  KAN: 'Kano',
}

const BusinessDetails = ({ user, agent_details, agent_status }) => {
  const status_names = {
    on_hold: 'On Hold',
    new: 'Pending',
    verified: 'Verified',
  }

  const industries = agent_details?.industry_type?.map((industry) => industryTypeMap[industry])
  const interestedServices = agent_details?.interested_services?.map((service) => interestedServicesMap[service])
  const shipmentVolume = shipmentVolumeMap[agent_details?.monthly_shipment_volume]

  console.log(agent_details, agent_status, user)

  const isAgentOnHold = agent_status?.verify_status?.toLowerCase() === 'on_hold'
  const isAgentNew = agent_status?.verify_status?.toLowerCase() === 'new'
  const isAgentVerified = agent_status?.verify_status?.toLowerCase() === 'verified'
  const isAgentOnHoldOrVerified = isAgentOnHold || isAgentVerified

  const Detail = ({ label, value }) => {
    return (
      <div className='md:border-r md:mx-3'>
        <p className='grey-text font-light text-xs'>{label}</p>
        <p className='black-text-3 text-xs text-wrap capitalize font-medium mt-1.5'>{value}</p>
      </div>
    )
  }

  return (
    <>
      {isAgentNew && (
        <div className='mb-8'>
          <CBTNotification
            isBold={false}
            businessProfileInfo
            isClosable={false}
            text='You have to add business details to complete your onboarding.'
          />
        </div>
      )}
      <div className='border-grey rounded mb-6'>
        <div className='flex items-center justify-between bottom-divider-2 p-6 pb-4'>
          <div className='flex items-center gap-x-2'>
            <p className='black-text-3 font-medium capitalize'>{`${user.firstname} ${user.lastname}`}</p>
            <p>
              <img src={divider} alt='' />
            </p>
            <p
              className={`rounded-full text-xs ${
                agent_details?.verify_status?.toLowerCase() === 'on_hold'
                  ? 'text-[#AD0013] bg-[#FFEDEE]'
                  : agent_details?.verify_status?.toLowerCase() === 'new'
                  ? 'text-[#AD0013] bg-[#FFEDEE]'
                  : agent_details?.verify_status?.toLowerCase() === 'verified'
                  ? 'text-[#005D00] bg-[#99FF7D2E]'
                  : ''
              }  font-medium py-1 px-4 capitalize`}
            >
              {(status_names[agent_details?.verify_status] || status_names[agent_status?.verify_status]) ?? 'N/A'}
            </p>
          </div>
          <div>
            <Link to='/settings/business-edit' className='flex items-center gap-1 ml-auto text-base'>
              <img src={pencil} alt='' />
              <span className='green-text text-sm hidden md:block'>Edit details</span>
            </Link>
          </div>
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-3 max-md:gap-x-6 gap-y-6 p-6'>
          <Detail label='Company Name' value={user.company_name} />
          <Detail label='Company Address' value={user.company_address || 'Nil'} />
          <Detail label='CAC Number' value={agent_details.cac_number || 'Nil'} />
          <Detail label='Business Type' value={agent_details.business_type || 'Nil'} />
          <Detail label='Industry Type' value={industries?.join(', ') || 'Nil'} />
          <Detail label='Monthly Shipment Volume' value={shipmentVolume || 'Nil'} />
          <Detail label='Interested Services' value={interestedServices?.join(', ') || 'Nil'} />
          <Detail label='Major Port of Shipment' value={portOfShipment[agent_details.major_port_of_shipment] ?? 'Nil'} />
        </div>
      </div>
    </>
  )
}

export default BusinessDetails
