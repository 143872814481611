import { TourButton } from 'store/onboarding/type'
import { useSelector, useDispatch } from 'react-redux'
import { updateTourStatus } from 'store/actions'
import { useTour } from '@reactour/tour'
import { useNavigate } from 'react-router-dom'

const TourPrevious = (props:TourButton<number, void>) => {
  const {currentStep} = useTour()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handlePrevousTour = () => {

    if(window.innerWidth < 1100) {
      if(props.currentStep == 5) {
        props.setIsOpen(false)
        navigate("/dashboard")
        setTimeout(() => {
          
          props.setIsOpen(true)
          props.setCurrentStep(4)
        }, 1000)
      }
      if(props.currentStep == 8) {
        props.setIsOpen(false)
        
      }
    }
    else {
      if(props.currentStep == 5) {
      props.setIsOpen(false)
          setTimeout(() => {
            navigate("/dashboard")
            props.setIsOpen(true)
            props.setCurrentStep(4)
          }, 800)
    }
    if(props.currentStep == 8) {
      props.setIsOpen(false)
          setTimeout(() => {
            navigate("/booking")
            props.setIsOpen(true)
            props.setCurrentStep(7)
          }, 800)
    }
    }
    
    props.setCurrentStep(currentStep > 0 ? currentStep - 1 : currentStep)
  }
  console.log(currentStep)
  if(currentStep !== 0)  {
    return (
    <button onClick={handlePrevousTour} className='text-black-4 text-black cursor-pointer text-[12px]' style={{fontWeight: 700}}>Prev</button>
  )
  }
  return (
    <span>
    </span>
  )
  
}

export default TourPrevious