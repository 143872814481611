import { generateActions } from 'helpers/generateActions'

export const GET_AGENT_DETAILS = generateActions('GET_AGENT_DETAILS')

export const CHANGE_PASSWORD = generateActions('CHANGE_PASSWORD')

export const EDIT_AGENT = generateActions('EDIT_AGENT')

export const API_ERROR = 'API_ERROR'

export const UPDATE_AGENT_TOUR_STATUS = generateActions("UPDATE_AGENT_TOUR_STATUS")


