export const shipmentVolumeOptions = [
  { label: 'Less than 10', value: 'less_than_10' },
  { label: '10 - 19', value: '10 - 19' },
  { label: '20 - 49', value: '20 - 49' },
  { label: '50 and above', value: '50_and_above' },
]

export const shipmentVolumeMap = {
  less_than_10: 'Less than 10',
  '10 - 19': '10 - 19',
  '20 - 49': '20 - 49',
  '50_and_above': '50 and above',
}

export const portOfShipmentOptions = [
  { label: 'Lagos', value: 'LOS' },
  { label: 'Port Harcourt', value: 'PHC' },
  { label: 'kano', value: 'KAN' },
]

export const businessTypeOptions = [
  { label: 'Import', value: 'import' },
  { label: 'Export', value: 'export' },
]


  export const modeOfContacts = [
    { label: 'Audio Call', value: 'audio Call' },
    { label: 'Video Call', value: 'video Call' },
  ]


  export const interestedServices = [
    { label: 'Ocean Freight', value: 'ocean_freight' },
    { label: 'Air Freight', value: 'air_freight' },
    { label: 'Haulage', value: 'haulage' },
    { label: 'Custom Brokerage (CBT)', value: 'custom_brokerage' },
  ]

export const industryTypeOptions = [
  { label: 'Fast Moving Goods', value: 'fast_moving_goods' },
  { label: 'Chemicals', value: 'chemicals' },
  { label: 'Pharmaceuticals', value: 'pharmaceuticals' },
  { label: 'Automobiles', value: 'automobiles' },
  { label: 'Machinery', value: 'machinery' },
  { label: 'Personal Effects', value: 'personal_effects' },
  { label: 'Aids & Reliefs', value: 'aids_and_reliefs' },
  { label: 'Diplomatic', value: 'diplomatic' },
  { label: 'Agro-Allied', value: 'agro_allied' },
  { label: 'Minerals and Ores', value: 'minerals_and_ores' },
  { label: 'Finished Goods', value: 'finished_goods' },
  { label: 'Plastics and Recyclables', value: 'plastics_and_recyclables' },
]

export const industryTypeMap = {
  fast_moving_goods: 'Fast Moving Goods',
  chemicals: 'Chemicals',
  pharmaceuticals: 'Pharmaceuticals',
  automobiles: 'Automobiles',
  machinery: 'Machinery',
  personal_effects: 'Personal Effects',
  aids_and_reliefs: 'Aids & Reliefs',
  diplomatic: 'Diplomatic',
  agro_allied: 'Agro-Allied',
  minerals_and_ores: 'Minerals and Ores',
  finished_goods: 'Finished Goods',
  plastics_and_recyclables: 'Plastics and Recyclables',
}

export const interestedServicesOptions = [
  { label: 'Ocean Freight', value: 'ocean_freight' },
  { label: 'Air Freight', value: 'air_freight' },
  { label: 'Haulage', value: 'haulage' },
  { label: 'Custom Brokerage (CBT)', value: 'custom_brokerage' },
  { label: 'Warehousing', value: 'warehousing' },
  { label: 'Insurance', value: 'insurance' }
]

export const interestedServicesMap = {
  ocean_freight: 'Ocean Freight',
  air_freight: 'Air Freight',
  haulage: 'Haulage',
  custom_brokerage: 'Custom Brokerage (CBT)',
  warehousing: 'Warehousing',
  insurance: 'Insurance',
}

