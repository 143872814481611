import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import Modal from "react-modal"
import closeSvg from 'assets/icons/close.svg'
import CustomInput from 'components/textInputs/CustomInput'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import OutlineButton from 'components/buttons/OutlineButton'
import SuccessModal from 'components/partials/SuccessModal'
import { useForm } from 'react-hook-form'
import { getCurrentTimestamp } from 'helpers'
import { requestCallBack, requestContactSalesRep } from 'store/actions'
import { useSelector, connect } from 'react-redux'
import { MultiSelect } from 'react-multi-select-component'
import { modeOfContacts } from 'pages/settings/options'
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'

interface ContactSalesRepModalProps<T, K> {
   loading: T,
   show: T;
   close: () => K,
   requestContactSalesRep: any;
   onSuccess: () => void;
 }


const ContactRepFormModal = ({loading, show, close, onSuccess, requestContactSalesRep}: ContactSalesRepModalProps<boolean, void>) => {
  
  const [selectedTime, setSelectedTime] = useState<string>('11:00-1:00pm')

  const [showSucessModal, setShowSuccessModal] = useState<boolean>(false)

  const [selectedContactMode, setSelectedContactMode] = useState<any>([])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: 'calc(100vw - 10%)',
      borderRadius: '10px',
      border: '0.01px solid #888',
    },
    overlay: {
      zIndex: '90',
      backgroundColor: 'rgba(6, 24, 2, 0.55)',
    },
    
  }

  const onSucess = () => {
    close()
    setShowSuccessModal(true)
  
  }

  let user = useSelector((state: any) => state.auth.user_data)
  let agent = useSelector((state:any) => state.auth.agent_data)

  

  agent = agent ? agent : JSON.parse(localStorage.getItem('agent_data') as string)

  user = user ? user : JSON.parse(localStorage.getItem('user_data') as string)

  const onSubmit = (data) => {

    try {
      const _data = {
        preferred_call_date: data.call_date,
        mode_of_contact: data.major_port_of_shipment.value,
        best_time_to_call: selectedTime,
        message_body: data.message
    }
      requestContactSalesRep(_data, onSuccess)
    } catch (error) {
      throw new Error(error?.message.data)
    }
   
    
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  


  const PreferredTime = ({ startTime, endTime, selectedTime, setSelectedTime }: {startTime: string, endTime: string, selectedTime: string, setSelectedTime: (time:string) => void}) => {
    return (
      <div
        className={`flex items-center black-text-4 text-sm font-light border-solid border-[1px] w-[111px] px-2 py-3 rounded cursor-pointer ${selectedTime === startTime + '-' + endTime ? 'border-[#139C33]' : 'border-[#E5E7EB]'}`}
        onClick={() => setSelectedTime(startTime + '-' + endTime)}
      >
        <span>{startTime}</span>
        <span>-</span>
        <span>{endTime}</span>
      </div>
    )
  }

  const pathName = window.location.pathname

  return (
    <>
    <Modal isOpen={show} style={customStyles} className={'newratemodal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between items-center pt-6 px-6 border-solid border-b-[1px] border-[#e6e7ec80]'>
          <div>
            <p className='text-xl black-text-3 font-medium'>Contact Support</p>
          </div>
          <>
            <img className='cursor-pointer' onClick={close} src={closeSvg} alt='close' />
          </>
        </div>

        <div className='py-4 md:py-8 px-6 flex flex-col gap-y-4 max-h-[calc(100vh_-_300px)] overflow-auto'>
        <div>
          <CustomDefaultSelect
          name={`major_port_of_shipment`}
          id={`major_port_of_shipment`}
          label={'Preferred Mode of Contact'}
          placeholder={'Select Major Port of Shipment'}
          isRequired={true}
          errors={errors}
          defaultValue={modeOfContacts[0]}
          options={modeOfContacts}
          icon=''
          isDisabled={false}
          control={control}
            />
        </div>
         <CustomInput
            control={control}
            name={'call_date'}
            id={'call_date'}
            label={'Select Preferred Call Date'}
            placeholder={''}
            isRequired={true}
            type='date'
            errors={errors}
            isDisabled={false}
            defaultValue={''}
            min={getCurrentTimestamp(1)}
            max={''}
            icon={''}
          />
          <div className='mb-3'>
            <p className='text-sm font-light grey-text'>What’s Your Preferred Call Time?</p>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center gap-4 mt-1.5'>
              <PreferredTime startTime='11:00' endTime='1:00pm' selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
              <PreferredTime startTime='1:30' endTime='3:30pm' selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
              <PreferredTime startTime='3:30' endTime='4:30pm' selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
              <PreferredTime startTime='4:30' endTime='6:00pm' selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </div>
          </div>
          <CustomTextarea
            control={control}
            name={'message'}
            id={'message'}
            label={'Message'}
            placeholder={'Leave your Message'}
            isRequired={true}
            errors={errors}
            isDisabled={false}
            defaultValue={''}
            icon=''
            style={{ height: '88px' }}
          />
        </div>
        <div className='p-4 flex flex-col gap-y-4 border-solid border-t-[1px] border-[#e6e7ec80] shadow-[0_-3px_32px_-12px_rgba(0,0,0,0.18)]'>
          <PrimaryButtons
            title={'Send'}
            style={{ color: '#59725C' }}
            disabled={false}
            loading={loading}
            icon={''}
           
            class_names={'newratebg'}
          />
          <OutlineButton title='Cancel' loading={false} disabled={false} icon={''} onClick={close} style={{ color: '#6B7280', width: '100%' }} />
        </div>
      </form>
    </Modal>
    </>
    )

}

const mapStateToProps = (state) => {

  const {loading, error} = state.contact

  return {loading, error}

}

export default connect(mapStateToProps, {requestContactSalesRep}) (ContactRepFormModal)