import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'

//icons
import close from 'assets/icons/close.svg'
import invalid from 'assets/icons/invalid.svg'

//components
import PrimaryButtons from 'components/buttons/PrimaryButtons'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '30px 30px',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    // WebkitOverflowScrolling: "touch",
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '10px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '99999999999',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

interface Props {
  modalIsOpen?: any
  setShowModal?: any
  closeModal?: any
  openEnquiryModal?: any
}

const InvalidCACModal = ({ modalIsOpen, openEnquiryModal, closeModal, setShowModal }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={customStyles}
        className={'successmodal'}
        contentLabel='Invalid CAC Modal'
      >
        <div onClick={closeModal} className='flex ml-auto cursor-pointer w-fit mb-2.5'>
          <img src={close} className='' alt='' />
        </div>
        <div className='flex flex-col'>
          <img src={invalid} alt='' width={95} className='mx-auto' />
          <div className=' mt-10 mb-14'>
            <p className='text-2xl black-text-2 font-semibold text-center my-3'>Notice!</p>
            <p className='black-text-4 text-sm text-center'>
              You have exhausted the allowed number of tries. Please contact support to resolve this.
            </p>
          </div>
          <div>
            <PrimaryButtons
              title='Contact Support'
              backgroundColor='bg-[#AD0013]'
              onClick={openEnquiryModal}
              disabled={false}
              loading={false}
              icon={''}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default InvalidCACModal
