import React from 'react'
import { TourButton } from 'store/onboarding/type'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateTourStatus } from 'store/actions'

const TourNextButton = (props:TourButton<number, void>) => {
 
  const navigate = useNavigate()
  const dispatch = useDispatch()

    const handleNextStep = () => {

      if(window.innerWidth < 1100){

        if(props.currentStep == 4){
          props.setIsOpen(false)
          setTimeout(() => {
            props.setIsOpen(true)
            props.setCurrentStep(5)
          }, 1000)
        }

        else if(props.currentStep == 6){
          props.setIsOpen(false)
          setTimeout(() => {
            props.setIsOpen(true)
            props.setCurrentStep(7)
          }, 1000)
        }
        else if(props.currentStep == 8){
          navigate("/dashboard")
          dispatch(updateTourStatus({is_agent_tour_completed: true}))
          props.setIsOpen(false)
        }
        
      }
      if(props.currentStep == 10){
        dispatch(updateTourStatus({is_agent_tour_completed: true}))
        props.setIsOpen(false)
        navigate("/dashboard")
      }
      props.setCurrentStep(props.currentStep + 1)
    }


  const buttonText = (currentStep: number) => {
  if(window.innerWidth > 1100){
    switch (currentStep) {
    case 4:
    return "Got It"
    default:
    return "Next"
   }
  }
  switch (currentStep) {
    case 4:
    return "Got It"
    default:
    return "Next"
   }
  }

  return (
    <button onClick={handleNextStep} className='bg-[#109B32] px-[8px] py-[3px] rounded-[4px] text-[12px] text-white cursor-pointer'>{buttonText(props.currentStep)}</button>
  )
}

export default TourNextButton