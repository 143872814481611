import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'

//icons
import mail from 'assets/dock/mail.svg'
import arrowL from 'assets/icons/arrow-left2.svg'

//libraries
import { useForm } from 'react-hook-form'

//actions
import { editAgent, getAgentDetails, updateUser, updateAgent } from 'store/actions'

//components
import Button from 'components/dock/Button'
import Layout from 'components/layout/Layout'
import CustomInput from 'components/textInputs/CustomInput'
import CustomPhoneInput from 'components/textInputs/CustomPhoneInput'
import CustomGoogleInput from 'components/textInputs/CustomGoogleInput'
import CBTNotification from 'components/rate/exportOcean/CBTNotification'
import SecondaryButtons from 'components/buttons/SecondaryButtons'
import PageLoading from 'components/partials/pageLoading'
import AgentEnquiryModal from 'components/settings/AgentEnquiryModal'

const ProfileEdit = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { loading, agent_details, getAgentDetails, edit_loading, editAgent, updateAgent } = props

  const [openModal, setOpenModal] = useState(false)

  let user = useSelector((state: any) => state.auth.user_data)
  let localStorageUser = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')!) : null
  user = user ? user : localStorageUser

  let agent_status = useSelector((state: any) => state.auth.agent_data)
  agent_status = agent_status || JSON.parse(localStorage.getItem('agent_data') || '{}')

  console.log('profile_afent>>>', agent_details)

  useEffect(() => {
    getAgentDetails()
  }, [])

  const navigate = useNavigate()

  const handleUpdateUser = (agent) => {
    updateAgent(agent)
    localStorage.setItem('agent_data', JSON.stringify(agent))
    console.log('updatedUser...', agent)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSubmit = (data: any) => {
    const newData = {
      kra_pin_number: data.kra_pin,
      incorporation_number: data.inc_num,
    }
    console.log('newDare>>>', newData)
    editAgent(newData, handleUpdateUser)
  }

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {loading ? (
          <PageLoading title='agent details' />
        ) : (
          <>
            <div className='flex items-center gap-x-1 mb-2 cursor-pointer w-fit' onClick={() => navigate('/settings/profile')}>
              <div>
                <img src={arrowL} alt=''></img>
              </div>
              <p className='text-sm grey-text'>Profile</p>
            </div>
            <p className='text-2xl mb-1'>Profile</p>
            <p className='grey-text text-sm'>Kindly update the necessary details</p>
            {agent_status?.verify_status?.toLowerCase() !== 'on_hold' && (
              <div className='w-4/5 max-w-[834px] mt-6'>
                <CBTNotification text='Please  contact  support  to make changes to your profile' isBold={false} editInfo={true} />
              </div>
            )}
            <form className='mt-10 w-full lg:w-4/5 lg:max-w-[834px]' onSubmit={handleSubmit(onSubmit)}>
              <div className='grid gap-3 grid-cols-1 md:grid-cols-2'>
                <CustomInput
                  control={control}
                  name={'company_name'}
                  id={'company_name'}
                  label={'Company name'}
                  placeholder={'E.g Max Movers Limited '}
                  isRequired={true}
                  type={'text'}
                  errors={errors}
                  isDisabled={true}
                  defaultValue={user.company_name}
                  min={''}
                  max={''}
                  icon={''}
                />
                <CustomInput
                  control={control}
                  name={'firstname'}
                  id={'firstname'}
                  label={'First Name'}
                  placeholder={'Input your first name'}
                  isRequired={true}
                  type='text'
                  errors={errors}
                  isDisabled={true}
                  defaultValue={user.firstname}
                  min={''}
                  max={''}
                  icon={''}
                />
                <CustomInput
                  control={control}
                  name={'lastname'}
                  id={'lastname'}
                  label={'Last Name'}
                  placeholder={'Input your last name'}
                  isRequired={true}
                  type='text'
                  errors={errors}
                  isDisabled={true}
                  defaultValue={user.lastname}
                  min={''}
                  max={''}
                  icon={''}
                />
                <CustomInput
                  control={control}
                  name={'email'}
                  id={'email'}
                  label={'Comany Email'}
                  placeholder={'@mail.com'}
                  isRequired={true}
                  type='text'
                  errors={errors}
                  isDisabled={true}
                  defaultValue={user.email}
                  min={''}
                  max={''}
                  icon={mail}
                />
                <CustomGoogleInput
                  icon=''
                  control={control}
                  name={'company_address'}
                  id={'company_address'}
                  label={'Company address'}
                  placeholder={'Enter your registered company address'}
                  isRequired={true}
                  errors={errors}
                  isDisabled={true}
                  defaultValue={user?.company_address}
                  allowWorldWide={true}
                  isBorderTransparent={true}
                />

                <CustomPhoneInput
                  control={control}
                  name={'phone_number'}
                  id={'phone_number'}
                  label={'Phone Number'}
                  isRequired={true}
                  defaultValue={user.phone}
                  placeholder={'Enter phone number'}
                  isDisabled={true}
                  errors={errors}
                />
              </div>

              <div className='mt-12 flex items-center gap-x-4 justify-end col-span-2'>
                <SecondaryButtons
                  title='Contact Support'
                  style={{ fontWeight: '400', backgroundColor: '#F9FAFB' }}
                  loading={false}
                  onClick={() => setOpenModal(true)}
                  type='button'
                />
                {agent_status?.verify_status?.toLowerCase() === 'on_hold' && (
                  <Button title='Update Profile' isColored={false} style={{ width: '100%', fontWeight: '500' }} loading={edit_loading} />
                )}
              </div>
            </form>
          </>
        )}
        <AgentEnquiryModal isOpen={openModal} closeModal={closeModal} />
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { loading, agent_details, edit_loading } = state.settings

  return { loading, agent_details, edit_loading }
}

export default connect(mapStateToProps, { editAgent, getAgentDetails, updateUser, updateAgent })(ProfileEdit)
