import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

//libraries
import moment from 'moment'
import { connect } from 'react-redux'

import { countries } from 'helpers/countries'

//icons
import copy from 'assets/icons/copy.svg'
import info from 'assets/icons/info.svg'
import divider from 'assets/icons/divider.svg'
import check from 'assets/icons/grey-check.svg'
import calendar from 'assets/icons/calendar.svg'
import shipment from 'assets/icons/shipment.svg'
import disclaimer from 'assets/icons/disclaimer-grey.svg'
import caretright from 'assets/icons/caretright-green.svg'
import track from 'assets/icons/notracking.svg'
import verify from 'assets/icons/verify.svg'
import hold from 'assets/icons/hold.svg'

//helpers
import { getOrigin, getDestination } from 'helpers/tableLocation'

//components
import Layout from 'components/layout/Layout'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import PageLoading from 'components/partials/pageLoading'
import SingleCard from 'components/dashboard/SingleCard'
import PageTitle from 'components/partials/PageTitle'
import WelcomeModal from 'components/Welcome/WelcomeModal'

//redux
import { getDashboardParams, getAgentDetails, clearShipment, clearBooking } from 'store/actions'
import { Doughnut } from 'react-chartjs-2'

import { Chart as ChartJS, ArcElement, Tooltip, Legend, controllers } from 'chart.js'
import { useTour } from '@reactour/tour'
import MobileSalesModal from 'components/salesRep/MobileSalesModal'
ChartJS.register(ArcElement, Tooltip, Legend)

const RecentBooking = ({ item }) => (
  <div className='py-5 px-6 bottom-divider-2'>
    <div className='flex items-center gap-x-2'>
      <p className='black-text font-normal'>{item.shipment_type === 'export' ? 'Export' : 'Import'}</p>
      <div>
        <img src={divider} alt='' />
      </div>
      <p className='black-text font-light capitalize'>
        {item.shipment_transport_type === 'ocean_freight' && 'Ocean Freight'}
        {item.shipment_transport_type === 'air_freight' && 'Air Freight'}
        {item.shipment_transport_type === 'haulage' && 'Haulage'}
        {item.shipment_transport_type === 'warehousing' && 'Warehousing'}
        {item.shipment_transport_type === 'customs_brokerage' && 'Customs Brokerage'}
      </p>
      <Link
        to={`/booking/${item?.['_id']}`}
        className='lg:hidden ml-auto text-sm green-text-2 self-center place-self-center cursor-pointer font-normal'
      >
        View
      </Link>
    </div>
    <div className='mt-6 grid grid-cols-3 lg:grid-cols-4 gap-x-2 lg:gap-x-0'>
      <div>
        <p className='font-light grey-text text-sm mb-1.5'>Origin</p>
        <p className='black-text-3 text-sm'>{getOrigin(item)}</p>
      </div>
      <div>
        <p className='font-light grey-text text-sm mb-1.5'>Destination</p>
        <p className='black-text-3 text-sm'>{getDestination(item)}</p>
      </div>
      <div className='self-center place-self-end lg:place-self-center'>
        {item.shipment_status === 'new booking' && (
          <p className='text-xs text-[#0047A9] py-1 px-2 rounded-full bg-[#d0f5ff4d] font-normal w-fit text-center'>New Booking</p>
        )}
        {item.shipment_status === 'awaiting quotes' && (
          <p className='text-xs text-[#C27500] py-1 px-2 rounded-full bg-[#FFFADF] font-normal w-fit text-center'>Awaiting Quotes</p>
        )}
        {item.shipment_status === 'cancelled' && (
          <p className='text-xs text-red-600 py-1 px-2 rounded-full bg-red-100 font-normal w-fit text-center'>Cancelled</p>
        )}
      </div>
      <Link
        to={`/booking/${item?.['_id']}`}
        className='hidden lg:block text-sm green-text-2 self-center place-self-center cursor-pointer font-normal'
      >
        View
      </Link>
    </div>
  </div>
)

const ActiveShipment = ({ item, handleCopyClick, isCopied }) => (
  <div className='py-5 px-6 bottom-divider-2'>
    <div className='flex items-center gap-x-2'>
      <p className='black-text font-normal'>{item.shipment_type === 'export' ? 'Export' : 'Import'}</p>
      <div>
        <img src={divider} alt='' />
      </div>
      <p className='black-text font-light'>
        {item.shipment_transport_type === 'ocean_freight' && 'Ocean Freight'}
        {item.shipment_transport_type === 'air_freight' && 'Air Freight'}
        {/* {item.shipment_transport_type  === "air_freight" && "Ocean Freight" } */}
        {item.shipment_transport_type === 'haulage' && 'Haulage'}
        {item.shipment_transport_type === 'warehousing' && 'Warehousing'}
        {item.shipment_transport_type === 'customs_brokerage' && 'Customs Brokerage'}
      </p>
      <Link
        to={`/booking/${item?.['_id']}`}
        className='lg:hidden ml-auto text-sm green-text-2 self-center place-self-center cursor-pointer font-normal'
      >
        View
      </Link>
    </div>
    <div className='mt-6 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-3'>
      <div className='lg:col-span-2 xl:col-span-1'>
        <p className='font-light grey-text text-sm mb-1.5'>Job Number</p>
        <p className='black-text-3 text-sm flex items-center gap-x-1'>
          <span>{item?.job_number}</span>
          <img src={isCopied[item._id] ? check : copy} className='cursor-pointer' alt='' onClick={() => handleCopyClick(item.job_number, item._id)} />
        </p>
      </div>
      <div className='lg:place-self-center'>
        <p className='font-light grey-text text-sm mb-1.5'>Date Created</p>
        <p className='black-text-3 text-sm'>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : 'N/A'}</p>
      </div>
      <Link
        to={`/shipments/${item?.['_id']}`}
        className='hidden lg:block text-sm green-text-2 self-center place-self-end xl:place-self-center cursor-pointer font-normal'
      >
        View
      </Link>
    </div>
  </div>
)

const Summmary = ({ dashboard_params, total }) => {
  const data = {
    labels: [],
    datasets: [
      {
        // label: 'Poll',
        data: [
          dashboard_params?.containers?.in_transit,
          dashboard_params?.containers?.file_opening,
          dashboard_params?.containers?.new,
          dashboard_params?.containers?.file_closed,
        ],
        backgroundColor: ['rgba(103, 152, 255, 1)', 'rgba(255, 201, 62, 1)', 'rgba(194, 117, 0, 1)', 'rgba(58, 180, 74, 1)'],
        borderColor: ['rgba(103, 152, 255, 1)', 'rgba(255, 201, 62, 1)', 'rgba(194, 117, 0, 1)', 'rgba(58, 180, 74, 1)'],
        cutout: '90%',
        border: '0',
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
  }

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bold 30px Inter'
      ctx.fillStyle = 'black'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dashboard_params?.containers?.total_containers}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    },
  }
  return (
    <>
      {/* <SingleCard
        to="booking"
        text="Total Booking"
        image={calendar}
        data={dashboard_params?.shipments?.total_bookings}
        style={{ color: "#3AB44A" }}
      /> */}
      <SingleCard
        to='shipments'
        text='Total Shipment'
        image={shipment}
        data={dashboard_params?.shipments?.total_shipments}
        style={{ color: '#6798FF' }}
      />
      <div className='border-grey rounded'>
        <p className='grey-text px-6 pt-6 pb-3.5 bottom-divider-2'>Total Container</p>
        <div className='flex flex-col items-center gap-5 mt-5 mb-9 p-3'>
          <div className='chart-container'>
            <Doughnut data={data} options={options} plugins={[textCenter]}></Doughnut>
          </div>
          <div className='grid grid-cols-2 gap-x-6 xl:gap-x-11 gap-y-3'>
            <div className='flex items-center gap-x-1'>
              <div className='w-3 h-3 bg-[#6798FF] rounded' />
              <span className='grey-text font-light'>In Transit ({dashboard_params?.containers?.in_transit})</span>
            </div>
            <div className='flex items-center gap-x-1'>
              <div className='w-3 h-3 bg-[#FFB323] rounded' />
              <span className='grey-text font-light'>In Progress ({dashboard_params?.containers?.file_opening})</span>
            </div>
            <div className='flex items-center gap-x-1'>
              <div className='w-3 h-3 bg-[#C27500] rounded' />
              <span className='grey-text font-light'>New ({dashboard_params?.containers?.new})</span>
            </div>
            <div className='flex items-center gap-x-1'>
              <div className='w-3 h-3 bg-[#3AB44A] rounded' />
              <span className='grey-text font-light'>Completed ({dashboard_params?.containers?.file_closed})</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const NoContainerToDisplay = () => (
  <div className='text-center flex flex-col justify-center items-center'>
    <div>
      <img src={track} alt='' />
    </div>
    <div className='mt-10'>
      <p className='text-xl grey-text'>No Containers To Display</p>
      <p className='text-sm grey-text-1 max-w-[313px]'>To view your tracking information you need to have active containers</p>
    </div>
  </div>
)

const SingleContainer = ({ item, frame, setFrame, setFrameLoading, containerNumber, setContainerNumber }) => (
  <div
    className={`border-b-solid border-b-[#e5e7eb] border-b-[1px] p-6 cursor-pointer ${
      containerNumber === item.container_number ? 'rate-item rounded' : ''
    }`}
    onClick={() => {
      setFrame(item._id)
      setContainerNumber(item.container_number)
      setFrameLoading(true)
    }}
  >
    <div
      className={`py-1.5 px-2 rounded-xl w-[150px] text-center 
          ${
            item.container_status.toLowerCase() === 'pending approval'
              ? 'background-green'
              : item.container_status.toLowerCase() === 'in transit'
              ? 'bg-[#ECFBFF]'
              : item.container_status.toLowerCase() === 'new'
              ? 'bg-[#FFFADF]'
              : item.container_status.toLowerCase() === 'file opening'
              ? 'bg-[#ffe75d33]'
              : item.container_status.toLowerCase() === 'file closed'
              ? 'bg-[#98ff9b4d]'
              : item.container_status.toLowerCase() === 'cancelled'
              ? 'bg-red-100'
              : 'bg-[#ededed]'
          }`}
    >
      <p
        className={`text-xs capitalize text-center ${
          item.container_status.toLowerCase() === 'in transit'
            ? 'text-[#296FD8]'
            : item.container_status.toLowerCase() === 'pending approval'
            ? 'text-[#059C01]'
            : item.container_status.toLowerCase() === 'new'
            ? 'text-[#C27500]'
            : item.container_status.toLowerCase() === 'file opening'
            ? 'text-[#DB8900]'
            : item.container_status.toLowerCase() === 'file closed'
            ? 'text-[#007200]'
            : item.container_status.toLowerCase() === 'cancelled'
            ? 'text-red-600'
            : ''
        }`}
      >
        {item.container_status.toLowerCase() === 'file opening' ? (
          <span>In Progress</span>
        ) : item.container_status.toLowerCase() === 'file closed' ? (
          <span>Completed</span>
        ) : (
          item.container_status
        )}
      </p>
    </div>
    <div className='flex justify-between items-center mt-6'>
      <div>
        <p className='grey-text text-sm mb-1.5'>Job Number</p>
        <p className='black-text uppercase text-sm font-medium'>
          {item?.shipment_details[0].job_number ? item?.shipment_details[0].job_number : 'N/A'}
        </p>
      </div>
      <div>
        <p className='grey-text text-sm mb-1.5'>Cont. Number</p>
        <p className='black-text uppercase text-sm font-medium'>{item?.container_number ? item?.container_number : 'N/A'}</p>
      </div>
    </div>
  </div>
)

const OnHold = ({ navigate }) => (
  <div className='flex flex-col gap-y-6 justify-center items-center h-[calc(99vh_-_240px)]'>
    <div>
      <img src={hold} alt='' />
    </div>
    <h2 className='text-[28px] black-text sato'>Account on Hold</h2>
    <p className='text-xl grey-text text-center max-w-[567px] sato'>
      Hello there! your account is currently on hold due to verification issues with the information provided. Please update the information you've
      submitted.
    </p>
    <PrimaryButtons title='Update Information' disabled={false} onClick={() => navigate('/settings/business-edit')} />
  </div>
)

const Dashboards = (props: any) => {
  const { getDashboardParams, loading, dashboard_params, clearShipment, clearBooking, agent_loading, agent_details, getAgentDetails } = props

  const navigate = useNavigate()

  let agent_status = useSelector((state: any) => state.auth.agent_data)
  agent_status = agent_status || JSON.parse(localStorage.getItem('agent_data') || '{}')
  
  // //user data
  let user = useSelector((state: any) => state.auth.user_data)
  user = user || JSON.parse(localStorage.getItem('user_data') || '{}')

  const scrollref = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const [showShipment, setShowShipment] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('')

  const [isCopied, setIsCopied] = useState<object>({})
  const [frame, setFrame] = useState('')

  const [frameLoading, setFrameLoading] = useState(false)

  const [total, setTotal] = useState(0)


  useEffect(() => {
    if (!dashboard_params) {
      setTotal(dashboard_params?.total_containers)
    }
  }, [dashboard_params])

  const openShipmentModal = () => {
    setModalIsOpen(true)
    // setModalText(text);
  }

  const closeShipmentModal = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token') || ''
    token && getDashboardParams()
    getAgentDetails()
  }, [])

  //copy function
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = (copyText, id) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied((prev) => ({
          ...prev,
          [id]: true,
        }))
        setTimeout(() => {
          setIsCopied((prev) => ({
            ...prev,
            [id]: false,
          }))
        }, 1100)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //creating the click outside to close drop down effect
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (showShipment && ref.current && !ref.current.contains(e.target)) {
        setShowShipment(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showShipment])

  const {isOpen, setIsOpen} = useTour()

  useEffect(() => {
    if(isOpen == true || agent_status.is_agent_tour_completed === false) {
     const handleResize = () => {
      setIsOpen(false)
        window.location.reload()
        navigate("/dashboard")
     }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }
  },[isOpen])

  return (
    <Layout>
      {loading || agent_loading ? (
        <PageLoading title='dashboard details.' />
      ) : (
        <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
           
          <PageTitle text={<>Welcome, {user.firstname}</>} subtext='Here’s an overview of all your activities' clearBooking={clearBooking} />
          {agent_details?.verify_status?.toLowerCase() === 'verified' || agent_status?.verify_status?.toLowerCase() === 'verified' ? (
            <div className='relative'>
           {agent_status.is_agent_tour_completed === false && dashboard_params.shipments?.total_bookings === 0 && <WelcomeModal/> }
              <div className="step-2 mobile-step-2">
                <div className='my-10 solid-br rounded'>
                  <p className='px-6 pt-6 pb-4 bottom-divider'>Overview</p>
                  <div className='grid grid-cols-1 lg:grid-cols-5'>
                    <div className='col-span-3 grid grid-cols-1 lg:grid-cols-2 p-6'>
                      <div className='lg:pr-10 lg:border-r-[1px] lg:border-r-solid lg:border-r-[#e5e7eb] pb-8 lg:pb-0 border-b-[1px] border-b-solid border-b-[#e5e7eb] lg:border-b-[0px]'>
                        <SingleCard
                          to='booking'
                          text='Total Bookings'
                          image={calendar}
                          data={dashboard_params?.shipments?.total_bookings}
                          style={{ color: '#3AB44A' }}
                        />
                        <div className='px-4 py-2 bg-[#F3F4F6] rounded flex items-start gap-x-3 mt-10'>
                          <div>
                            <img src={disclaimer} alt='' />
                          </div>
                          <p className='grey-text text-xs'>This displays all your bookings over a period of time</p>
                        </div>
                      </div>
                      <div className='lg:pl-10 pt-8 lg:pt-0'>
                        <SingleCard
                          to='shipments'
                          text='Total Shipments'
                          image={shipment}
                          data={dashboard_params?.shipments?.total_shipments}
                          style={{ color: '#6798FF' }}
                        />
                        <div className='px-4 py-2 bg-[#F3F4F6] rounded flex items-start gap-x-3 mt-10'>
                          <div>
                            <img src={disclaimer} alt='' />
                          </div>
                          <p className='grey-text text-xs'>This displays all your shipments over a period of time</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-span-2 lg:border-l-solid lg:border-l-[1px] border-l-[#e5e7eb] lg:pl-10 pl-6 pt-6 pr-6 border-t-[1px] border-t-solid border-t-[#e5e7eb] lg:border-t-[0px]'>
                      <div className='flex items-center gap-x-2'>
                        <p className='black-text-4'>Container Summary</p>
                        <div>
                          <img src={info} alt='' />
                        </div>
                      </div>
                      <p className='black-text-3 text-2xl mt-8 font-medium mb-2.5'>{dashboard_params?.containers?.total_containers}</p>

                      <div className='w-full flex'>
                        <div
                          className={`h-1.5 bg-[#296FD8]`}
                          style={{
                            width: `${(dashboard_params?.containers?.new / dashboard_params?.containers?.total_containers) * 100}%`,
                          }}
                        ></div>
                        <div
                          className={`h-1.5 bg-[#4CAF6E]`}
                          style={{
                            width: `${(dashboard_params?.containers?.in_transit / dashboard_params?.containers?.total_containers) * 100}%`,
                          }}
                        ></div>
                        <div
                          className={`h-1.5 bg-[#CC8400]`}
                          style={{
                            width: `${(dashboard_params?.containers?.file_opening / dashboard_params?.containers?.total_containers) * 100}%`,
                          }}
                        ></div>
                        <div
                          className={`h-1.5 bg-[#81899E]`}
                          style={{
                            width: `${(dashboard_params?.containers?.file_closed / dashboard_params?.containers?.total_containers) * 100}%`,
                          }}
                        ></div>
                      </div>

                      <div className='grid grid-cols-4 mt-7 pb-7 lg:pb-0'>
                        <div>
                          <p className='text-sm font-light text-[#296FD8]'>New Cont.</p>
                          <p className='text-[#4D525F] font-light mt-1.5'>{dashboard_params?.containers?.new}</p>
                        </div>
                        <div>
                          <p className='font-light text-sm text-[#4CAF6E]'>In Transit</p>
                          <p className='text-[#4D525F] font-light mt-1.5'>{dashboard_params?.containers?.in_transit}</p>
                        </div>
                        <div>
                          <p className='font-light text-sm text-[#CC8400]'>In Progress</p>
                          <p className='text-[#4D525F] font-light mt-1.5'>{dashboard_params?.containers?.file_opening}</p>
                        </div>

                        <div>
                          <p className='font-light text-sm text-[#81899E]'>Complete</p>
                          <p className='text-[#4D525F] font-light mt-1.5'>{dashboard_params?.containers?.file_closed}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-1 gap-y-8 lg:gap-y-0 lg:grid-cols-2 lg:gap-x-8 mt-8'>
                <div className='step-3 mobile-step-3'>
                  <div className='rounded border-grey-2'>
                    <div className='p-6 bottom-divider-2 flex items-center gap-x-1'>
                      <p>Recent Bookings</p>
                      <p className='text-sm w-[25px] h-[25px] p-1 rounded-[11px] bg-green-100 text-[#3AB44A] flex justify-center items-center'>
                        {dashboard_params?.shipments?.recent_bookings.length}
                      </p>
                    </div>
                    <div className=''>
                      {dashboard_params?.shipments?.recent_bookings?.length > 0 ? (
                        dashboard_params?.shipments?.recent_bookings.map((item) => <RecentBooking key={item._id} item={item} />)
                      ) : (
                        <div className='p-6 flex justify-center items-center min-h-[500px]'>
                          <div className=''>
                            <p className='grey-text text-xl'>No Recent Booking(s)</p>
                            <p className='text-sm font-light grey-text-1 max-w-[200px] text-center'>Your new bookings will be displayed here</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='step-4 mobile-step-4'>
                  <div className='rounded border-grey-2'>
                    <div className='p-6 bottom-divider-2 flex items-center gap-x-1'>
                      <p>Active Shipments</p>
                      <p className='text-sm w-[25px] h-[25px] p-1 rounded-[11px] bg-blue-100 text-[#4B83F0] flex justify-center items-center'>
                        {dashboard_params?.shipments?.active_shipments.length}
                      </p>
                    </div>
                    <div className=''>
                      {dashboard_params?.shipments?.active_shipments?.length > 0 ? (
                        dashboard_params?.shipments?.active_shipments.map((item) => (
                          <ActiveShipment key={item._id} item={item} isCopied={isCopied} handleCopyClick={handleCopyClick} />
                        ))
                      ) : (
                        <div className='p-6 flex justify-center items-center min-h-[500px]'>
                          <div className=''>
                            <p className='grey-text text-xl'>No Active Shipment(s)</p>
                            <p className='text-sm font-light grey-text-1 max-w-[200px] text-center'>Your active shipments will be displayed here</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <iframe src="https://www.youtube.com/embed/dXBohfjc4WA" width="100%" height="480" title="test"></iframe> */}
              {/* <div className="grid grid-cols-1 gap-y-8 lg:gap-y-0 lg:grid-cols-2 lg:gap-x-8 mt-8 items-start">
                <div className="rounded border-grey-2">
                  <div className="p-6 bottom-divider-2 flex items-center gap-x-1">
                    <p>Recent Bookings</p>
                    <p className="text-sm w-[25px] h-[25px] p-1 rounded-[11px] bg-green-100 text-[#3AB44A] flex justify-center items-center">{dashboard_params?.shipments?.recent_bookings.length}</p>
                  </div>
                  <div className="">
                    {dashboard_params?.shipments?.recent_bookings?.length > 0 ?
                      dashboard_params?.shipments?.recent_bookings.map((item) => (
                        <RecentBooking
                          key={item._id}
                          item={item}
                        />
                      )) :
                      <div className="p-6 flex justify-center items-center min-h-[500px]">
                        <div className="">
                          <p className="grey-text text-xl">No Recent Booking(s)</p>
                          <p className="text-sm font-light grey-text-1 max-w-[200px] text-center">
                            Your new bookings will be displayed here
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className="rounded border-grey-2">
                  <div className="p-6 bottom-divider-2 flex items-center gap-x-1">
                    <p>Active Shipments</p>
                    <p className="text-sm w-[25px] h-[25px] p-1 rounded-[11px] bg-blue-100 text-[#4B83F0] flex justify-center items-center">{dashboard_params?.shipments?.active_shipments.length}</p>
                  </div>
                  <div className="">
                    {dashboard_params?.shipments?.active_shipments?.length > 0 ?
                      dashboard_params?.shipments?.active_shipments.map((item) => (
                        <ActiveShipment
                          key={item._id}
                          item={item}
                          isCopied={isCopied}
                          handleCopyClick={handleCopyClick}
                        />
                      )) :
                      <div className="p-6 flex justify-center items-center min-h-[500px]">
                        <div className="">
                          <p className="grey-text text-xl">No Active Shipment(s)</p>
                          <p className="text-sm font-light grey-text-1 max-w-[200px] text-center">
                            Your active shipments will be displayed here
                          </p>
                        </div>
                      </div>
                    }
                  </div>

                </div>
              </div> */}
              <div className='bottom-[30px] right-[18.2px] fixed block lg:hidden z-50'>
                 <MobileSalesModal/>
              </div>
            </div>
          ) : agent_details?.verify_status?.toLowerCase() === 'on_hold' || agent_status?.verify_status?.toLowerCase() === 'on_hold' ? (
            <OnHold navigate={navigate} />
          ) : (
            <>
              <div className='p-6 border md:mt-9 rounded-[10px] md:items-center flex max-md:gap-8 max-md:flex-col justify-between'>
                <div className='flex flex-col gap-4'>
                  <h3 className='text-lg font-medium text-[#111827]'>Continue your Onboarding</h3>
                  <div className='w-full md:w-[500px] h-[5px] bg-[#E6F5E6] rounded-full relative'>
                    <div className='rounded-full h-full w-1/2 bg-[#3AB44A] absolute' />
                  </div>
                  <p>
                    You are <span className='text-[#00861E]'>50%</span> done, add business details to get verified.
                  </p>
                </div>
                <div>
                  <Link className='text-[#00861E] flex items-center font-medium' to='/settings/business-edit'>
                    Add Business Details{' '}
                    <span>
                      <img src={caretright} alt='caret right' />
                    </span>
                  </Link>
                </div>
              </div>
              <div className='flex flex-col gap-y-6 justify-center items-center h-[calc(99vh_-_240px)]'>
                <div>
                  <img src={verify} alt='' />
                </div>
                <h2 className='text-2xl md:text-[28px] text-center black-text sato'>Complete your Onboarding</h2>
                <p className='text-lg md:text-xl grey-text text-center max-w-[567px] sato'>
                  Welcome to OnePort 365. Please provide the following information to activate your agent account. Once we receive this information,
                  our team will review it and verify your account within the next few hours.
                </p>
                <PrimaryButtons title='Add Business Details' disabled={false} onClick={() => navigate('/settings/business-edit')} />
              </div>
            </>
          )}
        </main>
      )}
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading, dashboard_params } = state.dashboard
  const { loading: agent_loading, agent_details } = state.settings

  return { error, loading, dashboard_params, agent_loading, agent_details }
}

export default connect(mapStateToProps, { getDashboardParams, clearShipment, clearBooking, getAgentDetails })(Dashboards)
