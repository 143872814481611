import { post } from 'helpers/axios'
import { leaveMessageUrl, requestCallBackUrl, requestContactSalesRepUrl } from 'api/endpoints'

const leaveMessage = (data) => {
  return post(leaveMessageUrl, data)
}

const requestCallBack = (data: any) => {
  return post(`${requestCallBackUrl}`, data)
}

const requestContactSalesRep = (data: {
  preferred_call_date: string,
  mode_of_contact: string,
  best_time_to_call: string,
  message_body: string
}) => {
  return post(`${requestContactSalesRepUrl}`, data)
}

const contactServices = {
  leaveMessage,
  requestCallBack,
  requestContactSalesRep
}

export default contactServices
