import {useEffect, useState} from 'react'
import ContactRepFormModal from './ContactSalesRepModal'
import UserIcon from "../../assets/icons/User 05c.png"
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { useSelector } from 'react-redux'
import SuccessModal from 'components/partials/SuccessModal'

const SalesButton = () => {

  const [showSalesRepModal, setShowSalesRepModal] = useState<boolean>(false)

  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const[email, setEmail] = useState("")
  const [showSucessModal, setShowSuccessModal] = useState<boolean>(false)

  let agent = useSelector((state:any) => state.auth.agent_data)

  agent = agent ? agent : (JSON.parse(localStorage.getItem('agent_data') as string) ? JSON.parse(localStorage.getItem('agent_data') as string) : "")
  useEffect(() => {
    if(agent?.sales_agent_details === undefined) {
    setFirstName("")
    setLastName("")
    setEmail("")
    }
    else {
    setFirstName(agent?.sales_agent_details ? agent?.sales_agent_details[0].admin_details.firstname : "")
    setLastName(agent?.sales_agent_details ? agent?.sales_agent_details[0].admin_details?.lastname : "")
    setEmail(agent.sales_agent_details ? agent.sales_agent_details[0].admin_details.email : "")
    }
  
  }, [agent?.sales_agent_details, setEmail, setFirstName, setFirstName])
  
  
  const handleOnSuccess = () => {
    setShowSuccessModal(true)
    setShowSalesRepModal(false)
  }

  return (
    <>
    <div className='container relative bg-[#E8F5EB] mt-[41px] pt-[23px] self-start place-self-start z-50 pb-[16px] rounded-[8px] shadow-sm'>
      
      <div className='subContainer flex flex-row gap-[8px] pl-[16px] pr-[19px]'>
      <div className='rounded-[99px]'>
      <img className='image min-w-[50px] h-full' src={UserIcon} alt='user-image'/>
      </div>
      <div>
      <h2 className='text-[11px] font-semibold whitespace-nowrap break-normal'>{firstname} {""} {lastname}</h2>
      <p className='text-[10px] text-black-4 font-light break-all'>{email}</p>
      </div>
     </div>

     <div className='button block mt-[16px] px-[12px]'>
      <PrimaryButtons
        title='Contact Sales Rep' 
        style={{width: "184px"}}
        disabled={false}
        loading={false}
        icon={''}
        onClick={() => setShowSalesRepModal(true)}
          />
      </div>

    </div>

    {showSalesRepModal && (
      <ContactRepFormModal onSuccess={handleOnSuccess} show={showSalesRepModal} close={() => setShowSalesRepModal(false)}/>
    )}

   {showSucessModal &&
     <SuccessModal 
      modalIsOpen={showSucessModal}
      setShowSuccessModal={setShowSuccessModal}
      toDashboard={false}
      heading='Request Sent'
      text='Your request has been submitted. You will be contacted shortly.'
      />
     }

    </>
    
  )
}

export default SalesButton