import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

//components
import Layout from 'components/layout/Layout'
import CustomTabs from 'components/customTabs/CustomTabs'
import PageLoading from 'components/partials/pageLoading'
import BusinessDetails from './BusinessDetails'

//icons
import arrowL from 'assets/icons/arrow-left2.svg'
import disclaimer from 'assets/icons/cbt-info.svg'

import { getAgentDetails } from 'store/actions'
import ProfileDetails from './ProfileDetails'

const Profile = (props: any) => {
  const { loading, agent_details, getAgentDetails } = props

  const navigate = useNavigate()

  let user = useSelector((state: any) => state.auth.user_data)
  let localStorageUser = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')!) : null
  user = user ? user : localStorageUser

  let agent_status = useSelector((state: any) => state.auth.agent_data)
  agent_status = agent_status || JSON.parse(localStorage.getItem('agent_data') || '{}')

  const status_names = {
    on_hold: 'On Hold',
    new: 'New',
    verified: 'Verified',
  }

  useEffect(() => {
    getAgentDetails()
  }, [])

  const [activeTab, setActiveTab] = useState('Business Details')
  const tab_result = ['Business Details', 'Personal Details']

  console.log('profile_afent>>>', agent_status)

  console.log('agent_details', agent_details)

  const [invalidInfo, setInvalidInfo] = useState(true)

  const isAgentOnHold = agent_status?.verify_status?.toLowerCase() === 'on_hold'

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {loading ? (
          <PageLoading title='agent details' />
        ) : (
          <>
            <div className='flex items-center gap-x-1 mb-2 cursor-pointer w-fit' onClick={() => navigate('/settings')}>
              <div>
                <img src={arrowL} alt=''></img>
              </div>
              <p className='text-sm grey-text'>Settings</p>
            </div>
            <p className='text-2xl mb-6'>Profile</p>
            {/* Profile Tabs */}
            <div>
              <div className='mb-8 mt-8 flex items-end'>
                <CustomTabs tabs={tab_result} activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className='border-b border-[1px] border-[#E5E7EB] w-1/2 flex-1' />
              </div>
            </div>
            <div className='md:grid flex flex-col-reverse md:grid-cols-3 gap-6'>
              <div className='w-full md:col-span-2 gap-x-6 lg:gap-x-0'>
                {activeTab === tab_result[0] && <BusinessDetails user={user} agent_details={agent_details} agent_status={agent_status} />}
                {activeTab === tab_result[1] && <ProfileDetails agent_details={agent_details} agent_status={agent_status} user={user} />}
              </div>
              {isAgentOnHold && activeTab === tab_result[0] && (
                <div className=''>
                  {isAgentOnHold ? (
                    <div className='solid-br rounded'>
                      <div className='flex p-5'>
                        <div>
                          <p className=''>Invalid Information</p>
                        </div>
                        <div className='py-2 px-2'>
                          <img alt='disclaimer' src={disclaimer} className='w-3 cursor-pointer' />
                        </div>
                      </div>

                      <div className='bottom-divider'></div>

                      <div className='p-5'>
                        {isAgentOnHold && (
                          <div
                            className=' px-3 py-4 solid-br flex items-center mb-4 grey-text font-normal rounded-lg'
                            style={{ color: 'rgba(75, 85, 99, 1)' }}
                          >
                            <div className='flex '>
                              <p className='text-xs'>{agent_details?.last_verify_status_message}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { loading, agent_details } = state.settings

  return { loading, agent_details }
}

export default connect(mapStateToProps, { getAgentDetails })(Profile)
