import React, {useState} from 'react'
import {TourProvider} from "@reactour/tour"
import "./Tour.css"
import TourNextButton from './TourNextButton'
import TourSkip from './TourPrevious'
import { useTour } from '@reactour/tour'
import { useNavigate } from 'react-router-dom'
import "@reactour/popover/dist/index.css";
import {useMediaQuery} from "react-responsive"
import { useSelector, useDispatch } from 'react-redux'
import { updateTourStatus } from 'store/actions'

const Tour = ({children}: any) => {

  const navigate = useNavigate()

  const [step, setStep] = useState(0) 

  const isDesktop = useMediaQuery({minWidth: "1100"})

  const {isOpen, setIsOpen} = useTour()

  const dispatch = useDispatch()
  
    const steps =  [ 
          {
            content: <div>
               <h2 className='text-[18px] font-bold mb-[12px]'>Start New Shipment</h2>
              <p>Create a new shipping request by clicking on ‘Start New Shipment</p>
            </div>,
            selector: ".step-1",
            title: "",
            position: "left",
            spotlightClicks: true,
            spotlight:{
              backgroundColor: "#fff",
            },
            
          },
          {
            content:<div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Overview Section</h2>
              <p>In the ‘Overview’ section, you will see your total bookings, shipments, and container summary information. This is a helpful way to track your overall shipping activity and your current shipping status.</p> </div>,
            selector: ".step-2",
            position: "left",
            title:<h2 className='text-[18px] font-bold'>Overview Section</h2>,
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Recent Bookings</h2>
              <p>Here you can find quick information about your most recent bookings.</p>
              </div>,
            selector: ".step-3",
            title: <h2 className='text-[18px] font-bold'>Recent Bookings</h2>,
            
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Active Shipments</h2>
              <p>Here you can find details of your current shipment.</p>
              </div>,
            selector: ".step-4",
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Sales Rep Card</h2>
              If you need help or have questions about the booking process, you can contact your dedicated shipping assistant.
              </div>,
            selector: ".step-5",
            locale: { last: <p aria-label="skip">Got It</p> },
            data: {
                next: '/booking',
            }
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Bookings</h2>
            <p>In this section, you can create a new shipping request, see a list of shipping request and filter based on shipment type, status, and estimated shipping date.</p>
            </div>,
           selector: ".step-6",
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Search Functionality</h2>
            Search for and retrieve information about a particular shipping request
            </div>
           ,
           selector: ".step-7",
           title: <h2 className='text-[18px] font-bold text-left'>Search Functionality</h2>,
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Filter Functionality</h2>
            <p>Find specific bookings based on ‘Shipment Type’, ‘Status’, and ‘Estimated Shipping Date’</p>
            </div>,
           selector: ".step-8",
           title: "",
           position: "left",
           data: {
             prev: "/booking",
             next: '/Shipments',
         },
         styles: {
           options: {
             zIndex: 10000,
           },
         },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Shipments</h2>
            In this section, you can create and manage shipments and access detailed information about your shipments.
            </div>,
           selector: ".step-9",
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Search Functionality</h2>
            Search for and retrieve information about a particular shipping request
            </div>,
           selector: ".step-10",
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Filter Funtionality</h2>
            Find specific bookings based on ‘Shipment Type’, ‘Status’, and ‘Estimated Shipping Date’
            </div>,
           selector: ".step-11",
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
        ]

        const mobileSteps =  [ 
          {
            content: <div>
               <h2 className='text-[18px] font-bold mb-[12px]'>Start New Shipment</h2>
              <p>Create a new shipping request by clicking on ‘Start New Shipment</p>
            </div>,
            selector: ".mobile-step-1",
            title: "",
            position: "top",
            spotlightClicks: true,          
          },
          {
            content:<div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Overview Section</h2>
              <p>In the ‘Overview’ section, you will see your total bookings, shipments, and container summary information. This is a helpful way to track your overall shipping activity and your current shipping status.</p> </div>,
            selector: ".mobile-step-2",
            position: "top",
            title:<h2 className='text-[18px] font-bold'>Overview Section</h2>,
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Recent Bookings</h2>
              <p>Here you can find quick information about your most recent bookings.</p>
              </div>,
            selector: ".mobile-step-3",
            position: "top",
            title: <h2 className='text-[18px] font-bold'>Recent Bookings</h2>,
            
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Active Shipments</h2>
              <p>Here you can find details of your current shipment.</p>
              </div>,
            selector: ".mobile-step-4",
            position: "top",
          },
          {
            content: <div>
              <h2 className='text-[18px] font-bold mb-[12px]'>Sales Rep Card</h2>
              If you need help or have questions about the booking process, you can contact your dedicated shipping assistant.              
              </div>,
            selector: ".mobile-step-5",
            position: "top",
          
            
          },
         
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Search Functionality</h2>
            Search for and retrieve information about a particular shipping request
            </div>
           ,
           selector: ".mobile-step-6",
           title: <h2 className='text-[18px] font-bold text-left'>Search Functionality</h2>,
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Filter Functionality</h2>
            <p>Find specific bookings based on ‘Shipment Type’, ‘Status’, and ‘Estimated Shipping Date’</p>
            </div>,
           selector: ".mobile-step-7",
           title: "",
           position: "left",
           data: {
             prev: "/booking",
             next: '/Shipments',
         },
         styles: {
           options: {
             zIndex: 10000,
           },
         },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Search Functionality</h2>
            Search for and retrieve information about a particular shipping request
            </div>,
           selector: ".mobile-step-8",
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
          {
           content: <div>
            <h2 className='text-[18px] font-bold text-left mb-[12px]'>Filter Funtionality</h2>
            Find specific bookings based on ‘Shipment Type’, ‘Status’, and ‘Estimated Shipping Date’
            </div>,
           selector: ".mobile-step-9",
           styles: {
             options: {
               zIndex: 10000,
             },
           },
          },
        ]
          const styles = {
            popover: (base, state) => {
              return {
                ...base,
                borderRadius: 8,
                ...doArrow(state.position, state.verticalAlign, state.horizontalAlign)
              };
            },
            badge: (base) => ({
              ...base,
              left: "auto",
              right: "-0.8125em",
              display: "none",
            }),
            maskWrapper: (base) => ({ ...base, color: "#000" }),
          };
          
          const opositeSide = {
            top: "bottom",
            bottom: "top",
            right: "left",
            left: "right"
          };
          
          const popoverPadding = 10;

          const radius = 10;
          
          function doArrow(position, verticalAlign, horizontalAlign) {
            if (!position || position === "custom") {
              return {};
            }
          
            const width = 16;
            const height = 12;
            const color = "white";
            const isVertical = position === "top" || position === "bottom";
            const spaceFromSide = 10;
          
            const obj = {
              [`--rtp-arrow-${
                isVertical ? opositeSide[horizontalAlign] : verticalAlign
              }`]: height + spaceFromSide + "px",
              [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
              [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${
                width / 2
              }px solid transparent`,
              [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${
                width / 2
              }px solid transparent`,
              [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`
            };
            return obj;
          }

          

          const handleSetCurrentStep =  (step) => {
            switch(step){
              case 0:
              navigate("/dashboard")
              break;
              case 5:
              navigate("/booking")
              break;
              case 8:
              navigate("/shipments")
              break;
              case 10:
                dispatch(updateTourStatus({is_agent_tour_completed: true}))
                break;
              default:
              break;
            }
            setStep(step)    
          }

          const handleMobileCurrentStep = (step) => {
           
            if(step < 5){
              for(let i=0; i < 5; i++){
              setStep(step)
              }
              setIsOpen(false) 
            }
            else if(step == 5){
              setIsOpen(false)
              navigate("/booking")
              setStep(step) //5
          
            }

            if(step < 7){
              for(let i=4; i < 7; i++){
              setStep(step)
              }
            }

            else if(step == 7){
              setIsOpen(false)
              navigate("/shipments")
              setStep(step) //6
            }

            if(step == 8){
              setIsOpen(false)
              dispatch(updateTourStatus({is_agent_tour_completed: true}))
             
            }

            setStep(step)
            
          }
      function setSteps () {
        if(window.innerWidth > 1100) {
           return steps
        }
        else {
          return mobileSteps
        }
      }

      function handleSetMobileCurrentStep (step) {
        if(window.innerWidth > 1100) {
          return handleSetCurrentStep(step)
       }
       else {
         return handleMobileCurrentStep(step)
       }
      }


      function Close(props) {
        const handleClose = () => {
          props.onClick()
          dispatch(updateTourStatus({is_agent_tour_completed: true}))
      }
        
        return (
          <button
            onClick={handleClose}
            className='absolute right-5 '
          >
            x
          </button>
        )
      }

  return (
    
    <TourProvider 
      currentStep={step}
      steps={setSteps() as any}
      styles={styles}
      padding={{ popover: popoverPadding + 5 }}
      showDots={false}
      setCurrentStep={handleSetMobileCurrentStep}
      showPrevNextButtons={true}
      showCloseButton
      components={{ Close }}
    //   showBadge={false}
      onClickMask={() => {}}
      nextButton={(props) => {
        return (
           <TourNextButton {...props}/>
        )
      }}
      prevButton={(props) => {
        return (
           <TourSkip {...props}/>
        )
      }}
     >
     {children}
    </TourProvider>
  )
}

export default Tour