import React, { useState } from 'react'
import MessageEdit from "../../assets/icons/message-edit.svg"
import MessageClose from "../../assets/icons/message-close.svg"
import SalesButton from './SalesRepButton'

const MobileSalesModal = () => {

   const [showMobileSalesEdit, setMobileSalesRepEdit] = useState(true)

  return (
    <div className='relative mobile-step-5'>
       {!showMobileSalesEdit && 
       <div className='absolute top-[-193px] left-[-139px]'>
        <SalesButton/>
        </div>
        }
       {<img onClick={() => setMobileSalesRepEdit(!showMobileSalesEdit)} src={showMobileSalesEdit ? MessageEdit : MessageClose} alt='messagesales'/> }
    </div>

  )
}

export default MobileSalesModal