import { GET_AGENT_DETAILS, CHANGE_PASSWORD, EDIT_AGENT, API_ERROR, UPDATE_AGENT_TOUR_STATUS } from 'store/settings/constants'

export const getAgentDetails = () => {
  return {
    type: GET_AGENT_DETAILS.REQUEST,
    payload: null,
  }
}

export const getAgentDetailsSuccess = (response: any) => {
  return {
    type: GET_AGENT_DETAILS.SUCCESS,
    payload: response,
  }
}

export const changePassword = (data: any, callback: any, isInApp: boolean = false) => {
  return {
    type: CHANGE_PASSWORD.REQUEST,
    payload: { data, isInApp, callback },
  }
}

export const changePasswordSuccess = (response: any) => {
  return {
    type: CHANGE_PASSWORD.SUCCESS,
    payload: response,
  }
}

export const editAgent = (data: string, callback: any) => {
  return {
    type: EDIT_AGENT.REQUEST,
    payload: { data, callback },
  }
}
export const editAgentSuccess = (response: any) => {
  return {
    type: EDIT_AGENT.SUCCESS,
    payload: response,
  }
}

export const settingsApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const updateTourStatus = (data: {is_agent_tour_completed:true}) => {
 return {
   type: UPDATE_AGENT_TOUR_STATUS.SUCCESS,
   payload: data
 }
}
